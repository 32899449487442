import styled from "styled-components";
import { Link } from "gatsby";
import media from "styled-media-query";
import BackgroundImage from "gatsby-background-image";

export const CaseItemLink = styled(Link)`
  color: var(--esx-black);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 5px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
`;

export const CaseItemWrapper = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem 2rem 0 2rem;
`;

export const CaseItemImage = styled(BackgroundImage)`
  height: 15rem;
  width: 100%;
  background-size: cover;
  &::after {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &::before {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
`;

export const CaseTagsWrapper = styled.ul`
  list-style-type: none;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  font-size: 0.7rem;
  width: 100%;
  padding-top: 1rem;
`;
export const CaseTag = styled.li`
  text-align: center;
`;
export const CaseItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const CaseItemIcons = styled.div`
  padding-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
  text-align: left;
  width: 100%;
  p {
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
    font-size: 0.7rem;
  }
  ${media.lessThan("medium")`
    grid-gap: .5rem;
    padding-top: 1rem;
  `}
`;

export const CaseItemDate = styled.time`
  font-size: 0.7rem;
  ${media.lessThan("medium")`
    font-size: .7rem;
  `}
`;
export const CaseItemTag = styled.div`
  align-items: center;
  border-radius: 50px;
  background: ${(props) => (props.background ? props.background : "#1fa1f2")};
  position: absolute;
  color: #fff;
  font-size: var(--label-size);
  width: auto;
  margin-top: -5rem;
  justify-content: center;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
`;

export const CaseItemTitle = styled.h1`
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.3rem;
  color: var(--esx-black);
  font-weight: 700;
  ${media.lessThan("medium")`
    font-size: 1rem;
  `}
`;
export const CaseItemButtons = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: center;
  margin-top: 2rem;
  gap: 0.5rem;
  ${media.lessThan("medium")`
    margin-top: 1rem;
  `}
`;
export const CaseItemExternalLink = styled.a`
  width: 100%;
  color: var(--esx-red);
  font-size: 0.8rem;
  padding: 0.5rem;
  border-radius: 3rem;
  font-weight: bold;
  border: 2px solid var(--esx-red);

  &:hover {
    color: var(--esx-red);
  }
  ${media.lessThan("medium")`
    font-size: .7rem;
  `}
`;
export const CaseItemButton = styled(Link)`
  width: 100%;
  color: #fafafa;
  font-size: 0.8rem;
  padding: 0.5rem;
  border-radius: 3rem;
  font-weight: bold;
  background: var(--esx-red);
  &:hover {
    color: #fafafa;
  }
  ${media.lessThan("medium")`
    font-size: .7rem;
  `}
`;

export const CaseItemDescription = styled.p`
  display: -webkit-box;
  font-size: 0.8rem;
  margin: 1rem 0 2rem 0;

  font-weight: 300;
  line-height: 1.2;
  max-width: 100%;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  ${media.lessThan("medium")`
    margin-top: 1rem;
    font-size: .9rem;
  `}
`;

export const CaseItemBorder = styled.hr`
  border-radius: 5px 5px 0 0;
  width: 70%;
  background-color: transparent;
  align-self: center;
  margin-top: auto;
  margin-bottom: 0;
  border-bottom: 0.5rem solid var(--esx-red);
  ${media.lessThan("medium")`
  `}
`;
