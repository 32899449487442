import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import * as S from "./cases-list/styled";
import { graphql } from "gatsby";
import CaseItem from "../components/CaseItem";
import { useState, useEffect } from "react";
import HeroSection from "../components/HeroSection";

const CasesList = (props) => {
  const casesList = props.data.cases.nodes;
  const esxTheme = {
    main: "var(--esx-red)",
    color: "#fafafa",
    gradient: "linear-gradient(var(--esx-red), var(--esx-red))",
    textColor: "var(--esx-dark)",
  };
  //state hooks
  const [searchText, setSearchText] = useState("");
  const [casesFiltered, setCasesFiltered] = useState(casesList);
  useEffect(() => {
    const casesResult = () => {
      if (searchText) {
        const exp = new RegExp(searchText.trim(), "i");
        return casesList.filter(
          (c) =>
            exp.test(c.frontmatter.title) ||
            exp.test(c.frontmatter.description) ||
            exp.test(c.frontmatter.customer) ||
            exp.test(c.frontmatter.technologies.map((e) => e))
        );
      } else {
        return casesList;
      }
    };
    setCasesFiltered(casesResult);
  }, [searchText, casesList]);

  return (
    <Layout>
      <SEO
        title="Cases"
        image={props.data.seoImg}
        title="Conheça nossos cases"
      />
      <HeroSection
        theme={esxTheme}
        fixedImage={props.data.headerImg.childImageSharp.fluid}
        title="Conheça nossos Cases"
        text="As histórias de sucesso da ESX contadas através de nossos clientes"
      ></HeroSection>

      <S.SectionWrapper>
        <S.InputWrapper>
          <S.SearchInputLabel htmlFor="search-input">
            <S.SearchIcon />
          </S.SearchInputLabel>
          <S.SearchInput
            id="search-input"
            className="input"
            placeholder={`Buscar Cases`}
            onInput={(e) => setSearchText(e.target.value)}
            value={searchText}
          />
        </S.InputWrapper>
        {casesFiltered.length > 0 ? (
          <S.ItemsWrapper>
            {casesFiltered.map(
              ({
                frontmatter: {
                  date,
                  description,
                  title,
                  image,
                  color,
                  customer,
                  technologies,
                },
                fields: { slug },
              }) => (
                <CaseItem
                  slug={slug}
                  date={date}
                  title={title}
                  description={description}
                  image={image.childImageSharp.fluid}
                  color={color}
                  customer={customer}
                  technologies={technologies}
                />
              )
            )}
          </S.ItemsWrapper>
        ) : (
          <S.NoResultsAlert>
            Sem resultados para "{searchText}"
          </S.NoResultsAlert>
        )}
      </S.SectionWrapper>
      {/* <Pagination
        isFirst={isFirst}
        isLast={isLast}
        currentPage={currentPage}
        numPages={numcasesPages}
        prevPage={prevPage}
        nextPage={nextPage}
      /> */}
    </Layout>
  );
};

export const query = graphql`
  query CasesList($skip: Int!, $limit: Int!) {
    cases: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//collections/cases//" } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        frontmatter {
          date(
            formatString: "DD[ de ]MMMM[ de ]YYYY[, às ]HH[:]MM"
            locale: "pt-br"
          )
          description
          color
          title
          technologies
          keywords
          customer
          image {
            childImageSharp {
              fluid(
                maxHeight: 720
                maxWidth: 960
                toFormat: WEBP
                fit: COVER
                background: "#fafafa"
              ) {
                ...GatsbyImageSharpFluid_withWebp
                src
              }
            }
          }
        }
        fields {
          slug
        }
      }
    }
    headerImg: file(relativePath: { eq: "cover_cases_960x720px.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, maxHeight: 720, fit: CONTAIN) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    seoImg: file(relativePath: { eq: "thumbnail_cases_500x300px.png" }) {
      childImageSharp {
        fixed(width: 500, height: 300) {
          src
        }
      }
    }
  }
`;

export default CasesList;
