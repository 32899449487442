import styled from "styled-components";
import media from "styled-media-query";
import { SearchAlt2 } from "styled-icons/boxicons-regular/SearchAlt2";

export const SearchIcon = styled(SearchAlt2)`
  color: var(--esx-red);
  height: 2rem;
  width: 2rem;
`;
export const SectionWrapper = styled.section`
  padding: 2rem var(--default-padding-desktop);
  ${media.lessThan("medium")`
    padding: var(--default-padding-mobile);
  `}
`;
export const TitleWrapper = styled.div``;
export const SearchWrapper = styled.div`
  padding: 2rem var(--default-padding-desktop);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--esx-red);
  ${media.lessThan("medium")`
    padding: var(--default-padding-mobile);
  `}
`;
export const SearchInputLabel = styled.label`
  text-align: center;
  color: #fafafa;
`;
export const InputWrapper = styled.div`
  padding-bottom: 2rem;
  width: 50%;
  display: flex;
  align-items: center;
  margin: auto;
  gap: 1rem;
  ${media.lessThan("medium")`
    width: 100%;

  `}
`;
export const SearchInput = styled.input`
  background-color: #fdfdfd !important;
  color: var(--esx-red) !important;
  padding: 1.5rem !important;
  transition: border 0.5s;
  &::placeholder {
    color: var(--esx-red) !important;
    opacity: 0.8;
  }
  &:focus {
    border: 1px solid var(--esx-red) !important;
  }
`;

export const ItemsWrapper = styled.section`
  color: #fafafa;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 4rem 2rem;
  ${media.lessThan("large")`
  grid-template-columns: 1fr 1fr;
  
  `};
  ${media.lessThan("medium")`
    grid-template-columns: 1fr;
  `}
`;

export const NoResultsAlert = styled.div`
  background: #f2f2f2;
  margin: 1rem 0;
  padding: 1rem;
  text-align: center;
  width: 100%;
  color: var(--esx-red);
  overflow-wrap: break-word;
`;
