import React from "react";
import PropTypes from "prop-types";

import * as S from "./styled";

const CaseItem = ({
  slug,
  date,
  title,
  description,
  color,
  image,
  customer,
  technologies,
}) => (
  <S.CaseItemLink to={slug}>
    <S.CaseItemImage fluid={image} />
    <S.CaseItemWrapper>
      <S.CaseItemInfo>
        <S.CaseItemTag background={color ? color : "var(--esx-red)"}>
          {customer}
        </S.CaseItemTag>
        <S.CaseItemDate>{`${date}`}</S.CaseItemDate>
        <S.CaseItemTitle title={title}>{title}</S.CaseItemTitle>
        <S.CaseTagsWrapper>
          {technologies.slice(0, 5).map((keyword, i) => (
            <S.CaseTag key={i} title={keyword}>
              {keyword}
            </S.CaseTag>
          ))}
          {technologies.length > 5 ? (
            <S.CaseTag>{`+ ${technologies.length - 5}`}</S.CaseTag>
          ) : null}
        </S.CaseTagsWrapper>
        <S.CaseItemDescription title={description}>
          {description}
        </S.CaseItemDescription>
      </S.CaseItemInfo>
    </S.CaseItemWrapper>
    <S.CaseItemBorder
      style={{
        borderBottom: `0.5rem solid ${color ? color : "var(--esx-red)"}`,
      }}
    />
  </S.CaseItemLink>
);

CaseItem.propTypes = {
  slug: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  customer: PropTypes.string,
  technologies: PropTypes.array,
  image: PropTypes.any,
};

export default CaseItem;
